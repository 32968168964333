import React, { useEffect, useState } from 'react'
import './Navigation.css'

const Navigation = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const viewportHeight = window.innerHeight
      setScroll(window.scrollY > viewportHeight - 100);
    });
  }, []);
  return (
    <div className={`navigation ${scroll ? 'scrolled' : ''}`}>
        <div className='links'>
            <a href='#info'>Info</a>
            <a href='#songs'>Songs</a>
            <a href='#video'>Videos</a>
            <a href='#photos'>Photos</a>
            <a href='#gigs'>Gigs</a>
            <a href='#contacts'>Contacts</a>
        </div>
    </div>
  )
}

export default Navigation