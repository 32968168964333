import React from 'react'
import Gig from './Gig'
import './Gigs.css'
import gigs from './data/gigs.json'

const now = new Date()

const threshold = 1000 * 60 * 60 * 12

const pastGigs = gigs.filter(gig => {
  return new Date(gig.date) < now - threshold
})

const byDate = (a, b) => new Date(a.date) - new Date(b.date)

const futureGigs = gigs.filter(gig => {
  return new Date(gig.date) >= now - threshold
}).sort(byDate)

console.log(pastGigs, futureGigs)

const Gigs = () => {
  return (
    <section id='gigs' className='gigs'>
      <div className='gigs-content'>
        <h1>Gigs</h1>
        <h2>Upcoming</h2>
        <ul>
          {
            futureGigs.map(gig => (
              <Gig
                {...gig}
              />
            ))
          }
          {
            futureGigs.length < 1
            && 'Nothing planned yet'
          }
        </ul>
        <h2>Past gigs</h2>
        <ul>
          {
            pastGigs.map(gig => (
              <Gig
                {...gig}
              />
            ))
          }
          {/* <Gig
            venue='Nuit du Sport 2023'
            location='Park Molter L-3945 Mondercange'
            date={new Date('2023-05-13T18:00')}
            moreInfo={[
              'https://www.mondercange.lu/agenda/nuit-du-sport-2023',
              'https://www.facebook.com/events/170413282564496',
              'https://www.supermiro.lu/en/events/461593-reopening-park-molter-nuit-du-sport-et-monnerecher-laf-parc-molter-mondercange'
            ]}
          />
          <Gig
            venue='Hueschterter Kiermes Niederanven'
            location='Centre polyvalent A Schommesch 18, rue d&apos;Ernster'
            date={new Date('2023-05-20T20:00')}
            moreInfo='https://www.niederanven.lu/lu/agenda/hueschterter-kiermes'
          />
          <Gig
            venue='Merlettëfest Afterwork'
            location='53 Rue Jean-Pierre Hilger, Reckange-sur-Mess'
            date={new Date('2023-05-05T17:30')}
            moreInfo='https://reckange.lu/events/merlettefest'
          />
          <Gig
            venue='Bei Der Gare'
            location='Bei Der Gare bar, Luxembourg'
            date={new Date('2023-03-11T21:00')}
          />
          <Gig
            venue='Fete de la musique'
            location='Sandweiler'
            date={new Date('2022-06-16T18:00')}
          />
          <Gig
            venue='Corporate event'
            location='Steinsel'
            date={new Date('2022-04-14T19:00')}
          />
          <Gig
            venue='Private party'
            location='Hesperange'
            date={new Date('2022-04-09T20:00')}
          /> */}
        </ul>
      </div>
    </section>
  )
}

export default Gigs
