import React from 'react'
import DateBox from './DateBox'
import './Gig.css'
import pin from './images/pin.svg'

const Gig = ({
  isTBD,
  date,
  venue,
  location,
  moreInfo
}) => {
  return (
    <li className='gig'>
      <DateBox
        isTBD={isTBD}
        date={new Date(date)}
      />
      <div className="info">
        <div className="venue">
          {isTBD ? 'TBD' : venue}
        </div>
        <div className="location">
          {
            isTBD
              ? ''
              : <a className='location-link' href={`https://www.google.com/maps/search/?api=1&query=${location}`} target='_blank' rel='noreferrer'>
                  <span className='pin-icon'>
                    <img src={pin} alt='' height={20}/>
                  </span>
                  {location}
                </a>
          }
        </div>
        <div className="more-info">
          {
            moreInfo
              ? Array.isArray(moreInfo)
                ? moreInfo.map((link, index) => {
                  return <a href={link} class='more-info__link' target='_blank' rel='noreferrer'>Info link {index + 1}</a>
                })
                : <a href={moreInfo} class='more-info__link' target='_blank' rel='noreferrer'>More info</a>
              : null
          }
        </div>
      </div>
    </li>
  )
}

export default Gig