import React from 'react'
import instagram from './images/instagram.svg';
import techRider from './documents/mfh-tech-rider.pdf';
import bio from './documents/mfh-biography.pdf';

import './Contacts.css'

const Contacts = () => {
  return (
    <section id='contacts' className='contacts'>
        <h1>Downloads and contacts</h1>
        <p>
          Here you can find our
          <a target='_blank' href={techRider} rel="noreferrer">technical rider and stage plan</a>
          and <a target='_blank' href={bio} rel="noreferrer">biography</a>
        </p>
        <h2>For booking and info:</h2>
        <p>
          <a href='mailto:myfreezerhero@gmail.com'>
            myfreezerhero@gmail.com
          </a>
          <a
            href='tel:+352691568094'
          >
            +352 691 568 094
          </a>&nbsp;
          (We speak EN, FR, ES, IT, LU, DE)
        </p>
        <p>
          <a
            target="_blank"
            href='https://www.instagram.com/myfreezerhero/'
            rel="noreferrer"
          >
            <img src={instagram} alt='Instagram' width={87} height={100} />
          </a>
        </p>
    </section>
  )
}

export default Contacts