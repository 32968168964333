import background from './images/background-color.webp';
import './App.css';
import Setlist from "./Setlist";
import Video from "./Video";
import WhoWeAre from "./WhoWeAre";
import Contacts from "./Contacts";
import Gigs from "./Gigs";
import Photos from "./Photos";
import Intro from "./Intro";
import Navigation from './Navigation';
import musicians from './images/musicians.webp'

// preload image for fast first content pain
const link = document.createElement('link');
link.as = 'image';
link.href = musicians;
link.rel = 'preload';
link.type = "image/webp"
link.fetchpriority = "high"
document.head.appendChild(link);

function App() {
  return (
    <>
      <Navigation />
      <main
        className="app" style={{
        backgroundImage: `url(${background})`,
        backgroundAttachment: 'fixed'
      }}>
        <Intro />
        <WhoWeAre />
        <Setlist />
        <Video />
        <Photos />
        <Gigs />
        <Contacts />
      </main>
    </>
  );
}

export default App;
