import React, { useState } from 'react'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./Photos.css"

import _1 from './images/gallery/5.webp'
import _2 from './images/gallery/2.webp'
import _3 from './images/gallery/3.webp'
import _4 from './images/gallery/4.webp'
import _5 from './images/gallery/1.webp'
import _6 from './images/gallery/live_bdg.webp'
import _7 from './images/gallery/live_fdm.webp'
import _8 from './images/gallery/all1.webp'
import _9 from './images/gallery/ampli.webp'
import _10 from './images/gallery/live_merlette.jpg'
import _11 from './images/gallery/live_r.webp'
import _12 from './images/gallery/live_mon.webp'

const photos = [
  { src: _11 },
  { src: _12 },
  { src: _10 },
  { src: _6 },
  { src: _7 },
  { src: _1 },
  { src: _2 },
  { src: _3 },
  { src: _4 },
  { src: _5 },
  { src: _8 },
  { src: _9 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height
}));

const Photos = () => {
  const [index, setIndex] = useState(-1);
  return (
    <section id='photos'>
      <h1>Photo gallery</h1>
      <div className='image-gallery'>
        {
          photos.map((photo, index) => <div className='photo-wrapper'>
            <img alt='' className='photo' src={photo.src} height={300} onClick={() => setIndex(index)} />
          </div>)
        }
      </div>
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </section>
  )
}

export default Photos