import React from 'react'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import 'lite-youtube-embed/src/lite-yt-embed.js'
import './Video.css'

const video1Id = '2TL4KGpwOcI'
const video2Id = 'ae_Sqvjz7l0'

const Video = () => {
  return (
    <section
      id='video'
    >
        <h1>VIDEOS</h1>
        <div className='video-container'>
          <lite-youtube
            style={{
              maxWidth: 1200,
              margin: '0 auto'
            }}
            videoid={video1Id}
            playlabel="My freezer hero promo 2023"
          ></lite-youtube>
        </div>
        <div className='video-container'>
          <lite-youtube
            style={{
              maxWidth: 1200,
              margin: '0 auto'
            }}
            videoid={video2Id}
            playlabel="My freezer hero live @ Bei Der Gare"
          ></lite-youtube>
        </div>
    </section>
  )
}

export default Video