import React from 'react'
import blink from './images/bands/blink-logo.svg';
import foo from './images/bands/foo.svg';
import blur from './images/bands/blur.svg';
import killers from './images/bands/killers.svg';
import oasis from './images/bands/oasis-min.svg';
import greenday from './images/bands/greenday.webp';
import lit from './images/bands/lit.webp';
import millencolin from './images/bands/millencolin.webp'
import wheatus from './images/bands/wheatus.webp'
import am from './images/bands/am.webp'

import './Setlist.css'

const LOGO_WIDTH = 100
const LOGO_HEIGHT = 40

const logos = [
  {
    src: blink,
    alt: 'Blink 182'
  },
  {
    src: greenday,
    alt: 'Green Day'
  },
  {
    src: lit,
    alt: 'Lit'
  },
  {
    src: millencolin,
    alt: 'Millencolin'
  },
  {
    src: wheatus,
    alt: 'Wheatus'
  },
  {
    src: foo,
    alt: 'Foo fighters'
  },
  {
    src: blur,
    alt: 'Blur'
  },
  {
    src: killers,
    alt: 'The killers'
  },
  {
    src: oasis,
    alt: 'Oasis'
  },
  {
    src: am,
    alt: 'Artctic Monkeys'
  }
]

const Setlist = () => {
  return (
    <section id='songs'>
      <h1>WHAT SONGS DO WE PLAY?</h1>
      <p>
        <span className='bignumber'>90</span> minutes setlist featuring the greatest punk and rock hits of the
        '<span className='bignumber'>90</span>s
      </p>
      <div className='cover-bands'>
        {
          logos.map(
            logo => <div key={logo.alt} className="cover-band">
            <img width={LOGO_WIDTH} height={LOGO_HEIGHT} src={logo.src} className='cover-band-logo' alt={logo.alt}/>
          </div>
          )
        }
      </div>
    </section>
    )
  }
  
  export default Setlist