import React from 'react'
import './Info.css'

const WhoWeAre = () => {
  return (
    <section id='info' className='info'>
        <h1>MY FREEZER WHAT?</h1>
        <p>
        We are a punk rock cover band based in Luxembourg!
        </p>
        <p>
          Reach out to us for your next:
        </p>
        <ul>
          <li>gig at the local bar</li>
          <li>private party</li>
          <li>corporate event</li>
          <li>music festival</li>
        </ul>
        <p>
          We can perform anywhere!
        </p>
        <p>
          Also if you are a band, a musician, or a DJ interested in organizing a gig together, reach out to us!
        </p>
    </section>
  )
}

export default WhoWeAre 